
import { Options, Vue } from 'vue-class-component';
import UserInfo from '@/components/userInfo.vue';
import GrayHeader from '@/components/grayHeader.vue';
import { useStore, Store } from 'vuex';
import { useRouter, Router } from 'vue-router';
import { queryUserRealInfo } from '@/api/index';

declare global {
    interface Window {
        __TencentCaptchaExists__: number;
    }
}
@Options({
    components: {
        UserInfo,
        GrayHeader,
    },
})
export default class PersonalCenter extends Vue {
    private store: Store<any> = useStore();
    public router: Router = useRouter();
    private goPage() {
        this.router.push('./');
    }
    private realInfo = {
        IdCard: '',
        CurrName: '',
    };
    async mounted() {
        const id = 'AI_TCaptcha_Script';
        const temp = document.createElement('script');
        temp.setAttribute('id', id);
        temp.setAttribute('src', 'https://ssl.captcha.qq.com/TCaptcha.js');
        document.body.appendChild(temp);
        this.queryUserRealInfo();
    }
    private async queryUserRealInfo() {
        const { Data } = await queryUserRealInfo();
        if (Data) {
            try {
                const { IdCard, CurrName } = JSON.parse(Data);
                if (!IdCard || !CurrName) return;
                this.realInfo = { IdCard, CurrName };
            } catch (error) {
                console.log('控制台信息获取失败');
            }
        }
    }
    beforeUnmount() {
        const id = 'AI_TCaptcha_Script';
        const tempEle = document.getElementById(id) as HTMLScriptElement | undefined;
        if (tempEle) {
            document.body.removeChild(tempEle);
        }
        window.__TencentCaptchaExists__ = 0;
    }
}
